import Slider from "./Slider";
import PropTypes from "prop-types";
import styles from "../css/Slider.module.scss";

const SliderInput = ({
  minText,
  maxText,
  minVal,
  maxVal,
  title,
  value,
  onChangeSlider,
  doNotShowValue,
  unit,
}) => {
  return (
    <>
      <article className={styles.container}>
        <h2 className={styles.title}>{title}</h2>
        <div className={styles.slider__container}>
          <p>{minText}</p>
          <Slider
            minVal={minVal}
            maxVal={maxVal}
            value={value}
            onChangeSlider={onChangeSlider}
            doNotShowValue={doNotShowValue}
            unit={unit}
          />
          <p>{maxText}</p>
        </div>
      </article>
    </>
  );
};

SliderInput.propTypes = {
  minText: PropTypes.string.isRequired,
  maxText: PropTypes.string.isRequired,
  minVal: PropTypes.number.isRequired,
  maxVal: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  onChangeSlider: PropTypes.func.isRequired,
  doNotShowValue: PropTypes.bool,
  unit: PropTypes.string,
};

export default SliderInput;
