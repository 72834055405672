import SliderInput from "../components/SliderInput";
import NumberInput from "../components/NumberInput";
import styles from "../css/calculator.module.scss";
import { useState, useEffect } from "react";

const Calculator = ({ setOutput, accuracy, setAccuracy }) => {
  const [volume, setVolume] = useState(-10); // in dB
  const [distance, setDistance] = useState(30); // in meters
  const [location, setLocation] = useState(500); // 0-100 city-countryside
  const [amplitude, setAmplitude] = useState(0); // Controls the amplitude of the waves
  const [phase, setPhase] = useState(0); // Controls the phase of the waves

  const recalculate = () => {
    // normalizing and defining weight of each module. Following the format of normalized = (raw - min) / (max - min)
    //! maybe add these in an object?
    // Ask user how many kilometers (maybe mile toggle?) they're away from that city
    const normalVolume = ((volume - -100) / (10 - -100)) ** 2 * 60 * -1 + 60;
    const normalDistance = ((distance - 0) / (500 - 0)) * 60;
    const normalLocation = ((location - 0) / (1000 - 0)) ** 3 * 100;

    let newOutput = (normalVolume + (normalDistance + normalLocation)).toFixed(
      accuracy
    );
    setOutput(newOutput);
  };

  // const [ipInfo, setIpInfo] = useState([]);
  const [city, setCity] = useState("Loading...");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch(`https://ipinfo.io/?token=${process.env.REACT_APP_IPINFO_TOKEN}`, {})
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        // setIpInfo(data);
        setCity(data.city);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setError(error);
      });
  }, []);

  return (
    <div
      className={styles.calculator}
      style={{
        background: `
        url("./images/line.svg") -${phase / 10}rem -${amplitude / 2}rem repeat,
        ${
          amplitude >= 9.2
            ? `url("./images/line.svg") ${phase}rem -${
                amplitude / 2 + amplitude / 2
              }rem repeat, url("./images/line.svg") ${phase}rem ${
                amplitude / 4 - 2
              }rem repeat, `
            : `url("./images/line.svg") ${phase}rem -${
                amplitude / 2
              }rem repeat, url("./images/line.svg") ${phase}rem ${
                amplitude / 2
              }rem repeat, `
        }
        url("./images/line.svg") -${phase / 10}rem ${amplitude / 2}rem repeat`,
      }}
    >
      <SliderInput
        title="How loud are you playing your music?"
        minVal={-100}
        maxVal={10}
        minText="-inf"
        maxText="+10"
        value={volume}
        unit="dB"
        onChangeSlider={(value) => {
          setVolume(parseInt(value));
          setAmplitude(((value - -100) / (10 - -100)) * 10);
          recalculate();
        }}
      />
      <SliderInput
        title="How close do the nearest neighbours live?"
        minVal={0}
        maxVal={500}
        minText="literally next door"
        maxText="a few kilometers away"
        value={distance}
        unit="m"
        onChangeSlider={(value) => {
          setDistance(parseInt(value));
          setPhase(((distance - 0) / (500 - 0)) * 60);
          recalculate();
        }}
      />
      <NumberInput
        title={"I live " + location + " meters away from " + city}
        minVal={0}
        maxVal={1000}
        value={location}
        onChangeNumber={(value) => {
          setLocation(parseInt(value));
          recalculate();
        }}
      />
      {loading && <p>Loading city...</p>}
      {error && <p>Whoops, {error.toString()}</p>}
      {/* {ipInfo && <p>Your city: {ipInfo.city}</p>} */}
    </div>
  );
};

export default Calculator;
