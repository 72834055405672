import styles from "../css/Home.module.scss";
import NumberInput from "../components/NumberInput";

const Home = ({ accuracy, setAccuracy }) => {
  return (
    <div className={styles.home}>
      <h2 className={styles.title}>Welcome on the homepage</h2>
      <p>
        This is the crazy calculator! Check out more info on the about page or
        get calculating!
      </p>
      <p className={styles.subtitle}>Advanced settings:</p>
      <NumberInput
        title="amount of decimal places"
        minVal={0}
        maxVal={10}
        value={accuracy}
        onChangeNumber={(value) => {
          setAccuracy(parseInt(value));
        }}
      />
    </div>
  );
};

export default Home;
