// import "./normalize.css";
// import "./index.css";

import styles from "./css/App.module.scss";
import "./css/vars.css";
import "./index.css";
import { ROUTES } from "./config/routes";
import Button from "./components/Button";
import Timer from "./components/Timer";
import Calculator from "./pages/calculator";
import About from "./pages/About";
import Home from "./pages/Home";
import { useState } from "react";
import { Route, Switch, Link, NavLink } from "react-router-dom";
// import NumberInput from "./components/NumberInput";

function App() {
  const [output, setOutput] = useState("Change sliders to calculate");
  const [accuracy, setAccuracy] = useState(0); // Decimals after .

  const [showTimer, setShowTimer] = useState(false);
  const handleToggleTimer = () => {
    setShowTimer(showTimer ? false : true);
  };

  // Custom themes
  const [currentTheme, setCurrentTheme] = useState("dark"); // Default theme
  const themesList = ["dark", "light", "orange"]; // List of all themes to cycle through
  const handleToggleTheme = () => {
    setCurrentTheme(
      themesList[(themesList.indexOf(currentTheme) + 1) % themesList.length]
    );
  };

  return (
    <div className={styles.App + ` ${currentTheme}`}>
      {/*
        <li>Settings</li>{" "}
        allows the user to modify the min/max values of the sliders, possibly colors, or the weights maybe??
         
        message?
        input volume?

        Color flash when timer running out?
     */}
      <aside className={styles.aside}>
        <nav>
          <ul className={styles.navlist}>
            <li>
              <NavLink
                className={styles.navlist__item}
                exact
                strict
                to={ROUTES.HOME}
              >
                Home
              </NavLink>
            </li>
            <li>
              <NavLink className={styles.navlist__item} to={ROUTES.CALCULATOR}>
                Calculator
              </NavLink>
            </li>
            <li>
              <NavLink className={styles.navlist__item} to={ROUTES.ABOUT}>
                About
              </NavLink>
            </li>
          </ul>
        </nav>
        <Button
          value={
            "Switch to " +
            themesList[
              (themesList.indexOf(currentTheme) + 1) % themesList.length
            ] +
            " theme!"
          }
          onClick={handleToggleTheme}
        />

        <h3 className={styles.output}>{output} Minutes</h3>
        {showTimer ? (
          <Timer minutes={output} />
        ) : (
          <Button value="Start the countdown!" onClick={handleToggleTimer} />
        )}
      </aside>

      <section className={styles.main}>
        <Switch>
          <Route path={ROUTES.HOME} strict exact>
            <Home setAccuracy={setAccuracy} accuracy={accuracy} />
          </Route>
          <Route path={ROUTES.CALCULATOR} strict exact>
            <Calculator
              setOutput={setOutput}
              setAccuracy={setAccuracy}
              accuracy={accuracy}
            />
          </Route>
          <Route path={ROUTES.ABOUT} strict exact>
            <About />
          </Route>
          <Route>
            <p>Looks like you hit a dead end.</p>
            <p>
              Go back <Link to={ROUTES.HOME}>Home</Link>
            </p>
          </Route>
        </Switch>
      </section>
    </div>
  );
}

export default App;
