import styles from "../css/About.module.scss";

const About = () => {
  return (
    <div className={styles.about}>
      <h2 className={styles.title}>
        Want to push the limits of your sound system but don't want to deal with
        the cops?
      </h2>
      <p className={styles.subtitle}>
        This calculator will give you exact estimate of when your nearest
        neighbor will call the police and how much time you got after they're
        notified.
      </p>
      <p className={styles.subtitle}>
        Insert the values on the calculator page and you'll see a live update of
        the amount of time (in minutes) that you have left until the police are
        called. You can also start the timer and you'll know when to turn down
        the music 😉
      </p>
      <p className={styles.subtitle}>Happy listening!</p>
    </div>
  );
};

export default About;
