import PropTypes from "prop-types";
import styles from "../css/App.module.scss";
const Slider = ({
  minVal,
  maxVal,
  value,
  onChangeSlider,
  doNotShowValue,
  unit,
}) => {
  return (
    <>
      <input
        min={minVal}
        max={maxVal}
        type="range"
        value={value}
        onChange={(e) => onChangeSlider(e.target.value)}
      ></input>
      {doNotShowValue ? (
        ""
      ) : unit ? (
        <p className={styles.slider__unit}>
          {value} {unit}
        </p>
      ) : (
        value
      )}
    </>
  );
};

Slider.propTypes = {
  minVal: PropTypes.number.isRequired,
  maxVal: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  onChangeSlider: PropTypes.func.isRequired,
  doNotShowValue: PropTypes.bool,
  unit: PropTypes.string,
};

export default Slider;
