import PropTypes from "prop-types";
import styles from "../css/Number.module.scss";

const NumberInput = ({
  minText,
  maxText,
  minVal,
  maxVal,
  title,
  value,
  onChangeNumber,
}) => {
  return (
    <>
      <article className={styles.container}>
        <h2 className={styles.title}>{title}</h2>
        <div className={styles.number__container}>
          <p>{minText}</p>
          <input
            className={styles.number__input}
            min={minVal}
            max={maxVal}
            type="number"
            value={value}
            onChange={(e) => onChangeNumber(e.target.value)}
          ></input>
          <p>{maxText}</p>
        </div>
      </article>
    </>
  );
};

NumberInput.propTypes = {
  minText: PropTypes.string.isRequired,
  maxText: PropTypes.string.isRequired,
  minVal: PropTypes.number.isRequired,
  maxVal: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  onChangeNumber: PropTypes.func.isRequired,
};

export default NumberInput;
