import { useState, useEffect } from "react";

const Timer = ({ minutes }) => {
  const convertToOutput = (seconds) => {
    return (
      (seconds - (seconds %= 60)) / 60 + (9 < seconds ? ":" : ":0") + seconds
    );
  };

  const [secondsLeft, setSecondsLeft] = useState(~~(minutes * 60));
  const [timerOutput, setTimerOutput] = useState(
    convertToOutput(secondsLeft + 1)
  );

  useEffect(() => {
    const interval = setInterval(() => {
      secondsLeft > 0
        ? setSecondsLeft(~~(secondsLeft - 1))
        : clearInterval(interval);
      setTimerOutput(convertToOutput(secondsLeft));
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  });

  return (
    <>
      <p>Time left {timerOutput} minutes</p>
    </>
  );
};

export default Timer;
